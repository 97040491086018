import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import config from '../config'

// third-party
import { IntlProvider } from 'react-intl';
import flatten from "flat";

import  messages_en from 'utils/locales/en.json';
import  messages_es from 'utils/locales/es.json';
import  messages_fr from 'utils/locales/fr.json';
import  messages_nl from 'utils/locales/nl.json';

// old place to load locales files

// ==============================|| LOCALIZATION ||============================== //

const Locales = ({ children }) => {
    const customization = useSelector((state) => state.customization);
    const [messages, setMessages] = useState();

    const formats = {
        number: {
            USD: {
                style: 'currency',
                currency: config.currencyCode
            },
            CUR: {
                style: 'currency',
                currency: config.currencyCode
            }
        }
    }

    useEffect(() => {    
        switch (customization.locale) {
            case 'fr':
                setMessages(messages_fr); 
                break;
            case 'es':
                setMessages(messages_es); 
                break;
            case 'nl':
                setMessages(messages_nl); 
                break;
            default :
                setMessages(messages_en);
        }     
    }, [customization.locale]);

    return (
        <>
            {messages && customization.locale && (
                <IntlProvider locale={customization.locale} defaultLocale="en" messages={flatten(messages)} formats={formats} defaultFormats={formats}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

Locales.propTypes = {
    children: PropTypes.node
};

export default Locales;
